@import url(https://fonts.googleapis.com/css?family=Raleway);
.App {
  justify-content: center;
  margin: 0px auto;
}
strong {
  color:#e8f05b;
}
body {
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  margin: 0px auto;
  background-color: #182036;
  color: white;
}
h2 {
  font-weight: normal;
  letter-spacing: 4px;
  font-size: 48pt;
  margin: 5px;
  text-transform: uppercase;
}
.horizontal-bar {
  border-top: 6px solid #e8f05b;
  width: 100px;
  margin: 0px auto;
  padding-bottom: 50px;
}
p {
  text-align: left;
  line-height: 1.4;
  letter-spacing: 1px;
  font-size: 1rem;
  padding: 10px;
}
h3 {
  font-weight: normal;
  letter-spacing: 2px;
  font-size: 16pt;
  margin-top: 13px;
}
h4 {
  font-size: 12pt;
  padding: 5px;
}
.typing-demo {
  white-space: nowrap;
  overflow: hidden;
  font-size: 28pt;
  font-weight: normal;
  color: #e8f05b;
  letter-spacing: 2px;
}
#h2-header {
  text-transform: none;
  font-size: 28pt;
}
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre-wrap;
  min-height: 100vh;
}
.project-image {
  width: 450px;
  height: 320px;
  transition: 0.7s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.view-button {
  text-align: right;
  bottom: 0;
  position: absolute;
  right: 0;
  padding: 10px;
  border: 2px solid white;
  text-transform: uppercase;
}
.icon {
  width: 50px;
  height: 50px;
}
.icon:hover {
  opacity: 0.3;
}
/* .aboutme-container {
  display: flex;
}
.aboutme {
  max-width: 650px;
}
.profile-pic {
  border-radius: 50%;
  max-height: 450px;
  width: auto;
  opacity: 0.6;
  margin: auto;
} */

.aboutme-container {
  display: flex;
  flex-direction: column;
}
.aboutme {
  max-width: 650px;
  /* padding-top: 30px; */
  margin: 0px;
}
.aboutme-details {
  display: flex;
  justify-content: center;
}
.profile-pic {
  border-radius: 50%;
  max-height: 350px;
  width: auto;
  opacity: 0.6;
  margin: 0px 20px;

}
.profile-pic:hover {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.programming-icons {
  height: 50px;
  width: auto;
  padding-right: 20px;
}
#framework {
  text-align: center;
}
.projects-container {
  margin-top: 100px;
  position: relative;
  margin-right: 0px;
  overflow: hidden;
}
.projects {
  display: inline-grid;
  max-width: 950px;
  width: 450px;
  height: 320px;
  margin: 20px;
  box-shadow: 2px 5px 8px #808080;
  position: relative;
  opacity: 0.9;
}
.projects .overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
}
.projects:hover .overlay {
  opacity: 1;
  background: #151821;
}
.project-details {
  position: absolute;
  text-align: center;
  width: 370px;
  height: 280px;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in-out 0s;
}
.projects:hover .project-details {
  opacity: 1;
  top: 50%;
  left: 50%;
}
.fadeIn-bottom {
  top: 50%;
}
.project-site {
  text-decoration: none;
  color: #ffffff;
  letter-spacing: 1px;
  font-size: 12pt;
  text-align: right;
}
.project-p {
  padding: 0px auto;
  margin: 0px auto;
  font-size: 1rem;
}
.disable {
  opacity: 0.3;
  pointer-events: none;
}
.project-site:hover {
  color: #e8f05b;
}
.overlay {
  top: 0;
  left: 0;
  width: 450px;
  height: 300px;
}
/* button.close {
  position: absolute;
  right: 0;
  font-size: 14pt;
  padding: 10px;
} */
.tools {
  font-weight: normal;
  color: #e8f05b;
  padding: 0px auto;
  margin: 0px auto;
}
.contact-container {
  margin-top: 20px;
  padding-top: 50px;
  position: relative;
}
input {
  display: inline-block;
  width: 50%;
  line-height: 3em;
  font-family: "Raleway", sans-serif;
  font-size: 12pt;
  color: #ffffff;
  background: #2f364a;
  border: none;
}
textarea {
  width: 50%;
  line-height: 6em;
  font-family: "Raleway", sans-serif;
  font-size: 12pt;
  background: #2f364a;
  border: none;
  color: #ffffff;
}
.input-field:focus {
  outline: 1px solid #e8f05b;
}
#submit-button {
  height: 50px;
  width: 150px;
  text-transform: uppercase;
  color: white;
  background: #182036;
  font-size: 14pt;
  letter-spacing: 2px;
  padding: 0px;
}
#submit-button:hover {
  color: #e8f05b;
  cursor: pointer;
}
.contact-icon {
  height: 40px;
  width: 40px;
  padding: 40px 30px 30px 0px;
  display: inline;
}
.contact-icon:hover {
  opacity: 0.3;
}
#social-links {
  bottom: 0;
  margin-top: 50px;
}
a {
  color: white;
  font-size: 18pt;
  letter-spacing: 2pt;
}
a:hover {
  color: #e8f05b;
}
.navbar {
  text-align: right;
  padding-top: 20px;
  padding-right: 20px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  background: #151821;
  opacity: 0.6;
  height: 55px;
  width: 100%;
}
.navbar > a {
  margin-left: 20px;
  cursor: pointer;
}
.resume {
  border: #e8f05b solid 2px;
  padding: 10px;
  text-decoration: none;
}
.modal-background {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.2);
}
.modal {
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1;
}
.demo:hover {
  cursor: pointer;
}
.contact-h4 {
  margin: 0px auto;
  padding: 0px auto;
  color: #e8f05b;
  letter-spacing: 2px;
}
.contact-h3 {
  margin: 0px auto;
  padding-bottom: 7px;
}
.icon-align {
  bottom: 0;
  position: absolute;
  left: 0;
  text-align: left;
}
iframe {
  width: 853px;
  height: 480px;
  position: relative;
}
footer {
  /* background: #151821; */
  opacity: 0.6;
  height: 55px;
  width: 100%;
  padding-top: 26px;
  /* position: absolute; */
  opacity: 0.6;
  margin-right: 20px;
}

@media (max-width: 800px) {
  .html {
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    /* min-width: 100vh; */
  }
  .App {
    justify-content: center;
    max-width: 600px;
    margin: 0px 10px 0px 10px;
  }
  body {
    justify-content: center;
    display: flex;
  }
  .navbar > a {
    padding-right: 3px;
    margin: 7px;
    font-size: 0.7rem;
  }
  /* .navbar footer{
    height: 20px;
  } */
  #h2-header {
    font-size: 1.2rem;
    word-wrap: break-word;
    letter-spacing: 1px;
  }
  h2 {
    font-size: 1rem;
  }
  .typing-demo {
    font-size: 1.2rem;
    word-wrap: break-word;
    letter-spacing: 2px;
    position: absolute;
    display: block;
    margin-top: 50px;
  }
  h3 {
    font-size: 0.7rem;
  }
  h4 {
    font-size: 8pt;
  }
  .aboutme-container {
    position: relative;
    align-items: center;
    justify-content: center;
  }
  p.aboutme {
    position: relative;
    margin: 0px 15px 0px 15px;
    margin-top: 375px;
  }
  p {
    font-size: 0.75rem;
  }
  .profile-pic {
    position: absolute;
    width: 330px;
  }
  .projects-container {
    position: relative;
    overflow: auto;
    justify-content: center;
  }
  .projects {
    width: 350px;
    height: 220px;
    position: relative;
    margin-left: 10px;
    padding-left: 0px;
    box-shadow: none;
    overflow: hidden;
  }
  .project-details {
    text-align: center;
    width: 350px;
    height: 220px;
    left: 50%;
    opacity: 0.9;
    background: #151821;
    padding-top: 50px;
  }
  /* .projects .overlay {
    width: 330px;
    height: 220px;
  } */
  /* .projects .project-image{
    display: none;
  } */
  .project-image {
    width: 350px;
    height: 220px;
    opacity: 0.9;
  }
  .project-p {
    font-size: 8pt;
    width: 250px;
    height: 200px;
  }
  .icon-align {
    padding: 30px;
  }
  .view-button{
    padding: 40px;
  }
  .icon {
    width: 25px;
    height: 25px;
  }
  .contact-icon {
    width: 30px;
    height: 30px;
  }
  #submit-button {
    font-size: 0.5rem;
  }
  .input {
    font-size: 0.5rem;
  }
  .programming-icons {
    height: 30px;
    width: 30px;
  }
  .project-site {
    font-size: 0.5rem;
  }
  .view-button {
    border: none;
    bottom: 0;
    position: absolute;
    right: 0;
  }
  .icon-align {
    bottom: 0;
    position: absolute;
    left: 0;
    text-align: left;
    margin-bottom: 12px;
  }
  textarea {
    font-size: 0.7rem;
    width: 75%;
  }
  input {
    font-size: 0.7rem;
    width: 75%;
  }
  .horizontal-bar {
    width: 50px;
  }
  iframe {
    position: relative;
    left: 0;
    top: 0;
    width: 320px;
    height: 230px;
  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

